<!-- 学校发展平台 -->
<template>
  <div class="lvya-xxfzpt">
    <div class="w100 banner-top">
      <img src="static/lvya/schoolFzpt/developmentPlanning/lunbo4.jpg" alt="" style="width:100%;height: 100%;">
    </div>
    <div class="w100">
      <div class="text-div">
        <p class="title">基于办学目标的学校现代化管理平台</p>
        <p class="text-text">学校发展平台围绕学校办学目标，以学校信息化业务平台为载体，自上而下实现智慧校园顶层设计。</p>
        <p class="text-text no-top">平台将办学目标分解为具体任务，由任务承载学校各类业务，并通过各业务系统平台为任务执行提供实现方法与手段，
        从而保证目标达成有法可循。数据中台将各类应用数据相打通，凭借强大的数据建模能力和数据分析能力，
        实现目标实施过程可监督，实施效果可诊断。</p>
      </div>
      <div class="w1200 flex-item-dix">
        <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt09.png" alt="" class="wow animate__fadeInDown" data-wow-duration ="1.5s" data-wow-delay ="0s">
        <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt10.png" alt="" class="top40 wow animate__fadeInDown" data-wow-duration ="2.5s" data-wow-delay ="0s">
        <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt11.png" alt="" class="top60 wow animate__fadeInDown" data-wow-duration ="3.5s" data-wow-delay ="0s">
        <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt12.png" alt="" class="top50 wow animate__fadeInUp" data-wow-duration ="4.5s" data-wow-delay ="0s">
      </div>
      <div class="text-div">
        <p class="title">以质量评估为抓手，促进学校高质量发展</p>
        <p class="text-text">学校发展平台以质量评估为抓手，引导学校提升质量意识，建立完善的质量标准体系；</p>
        <p class="text-text no-top">
          通过信息技术手段，将质量标准转化为学校自身发展目标，同学校工作业务相融通，在学校、专业、
          课程、教师、学生不同层面建立起完整的自我质量保障机制，促进学校高质量发展。
        </p>
      </div>
    </div>
    <div class="w100-bg">
      <div class="w1200">
        <div class="left wow animate__backInLeft" data-wow-duration ="1.5s" data-wow-delay ="0s">
          <p class="p1">保障学校发展的一致性、可持续性</p>
          <p class="green-line"></p>
          <p class="text-p">学校发展平台将质量评估同学校发展目标相统一，确保二者在标准、手段、监督、诊断上的一致性，以质量评估带动学校发展，
            以学校发展提升质量评估，保障学校发展的可持续性。</p>
        </div>
        <div class="right wow animate__backInRight" data-wow-duration ="1.5s" data-wow-delay ="0s">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt03.png" alt="">
        </div>
      </div>
    </div>
    <div class="w100">
      <div class="w1200">
        <div class="top60">
          <p class="p1">实现评估标准与学校工作互融互通</p>
          <p class="green-line"></p>
          <p class="text-p">学校发展平台将质量评估标准分解为可执行、可诊断的目标任务，通过任务的执行、数据的采集与处理，形成涵盖学校、专业、课程、教师、学生等不同层面的质量评估报告，
            精准定位问题，促进学校内涵式发展。</p>
        </div>
        <div class="top60 flex-div wow animate__bounceInLeft" data-wow-duration ="1.5s" data-wow-delay ="0s">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt04.png" alt="">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt05.png" alt="">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt06.png" alt="">
        </div>
        <div class="top60 wow animate__fadeInDownBig" data-wow-duration ="2.5s" data-wow-delay ="0s">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt07.png" alt="">
        </div>
      </div>
    </div>
    <div class="w100-bg tb60">
      <div class="w1200">
        <div class="left wow animate__fadeInDown" data-wow-duration ="2s" data-wow-delay ="0s">
          <p class="p1">形成宏观办学目标下的人才质量保障体系</p>
          <p class="green-line"></p>
          <p class="text-p">学生成长和教师发展是提高学校教育质量的关键要素。学校发展平台从质量评估入手，将学生成长和教师发展放在有目的、
            有标准的评价环境之中，以评促改，有的放矢，保障教育质量良性发展。</p>
        </div>
        <div class="right wow animate__fadeInDown" data-wow-duration ="3s" data-wow-delay ="0s">
          <img src="static/lvya/schoolFzpt/developmentPlanning/xxfzpt08.png" alt="">
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
export default {
  mounted() {
    this.$nextTick(() => {
      let wow = new WOW({
          boxClass: 'wow',
          animateClass: 'animated',
          offset: '0',
          mobile: true,
          live: true
      })
      wow.init()
    })
  }
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.lvya-xxfzpt{
  .w100{
    width: 100%;
    .text-div{
      width: 1200px;
      margin: 0px auto;
      min-height: 300px;
      padding: 50px 0;
      .title{
        text-align: center;
        font-size: 24px;
        color: #333;
        font-weight: 600;
      }
      .text-text{
        margin-top: 20px;
        text-align: center;
        line-height: 30px;
        color: #666;
      }
      .no-top{
        margin-top: 0;
      }
    }
    .w1200{
      width: 1200px;
      margin: 0 auto;
      .top60{
        margin-top: 60px;
      }
      .p1{
        font-size: 24px;
        font-weight: 600;
        color: #333;
      }
      .green-line {
        width: 65px;
        height: 5px;
        background: #19ca81;
        margin: 10px 0 30px 0;
        border-radius: 2px;
      }
      .text-p {
        color: #555;
        line-height: 40px;
      }
      .flex-div{
        display: flex;
        justify-content: space-between;
        img{
          width: 403px;
          height: 290px;
        }
      }
    }
    .flex-item-dix{
      margin: 0 auto;
      text-align: center;
      img{
        max-width: 100%;
      }
      .top40{
        margin-top: -100px;
      }
      .top60{
        margin-top: -95px;
      }
      .top50{
        margin-top: -240px;
        margin-bottom: 60px;
      }
    }
  }
  .w100-bg{
    min-height: 200px;
    background: #f9f9f9;
    .w1200{
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .left{
        margin-right: 150px;
        .p1{
          font-size: 24px;
          font-weight: 600;
          color: #333;
        }
        .green-line {
          width: 65px;
          height: 5px;
          background: #19ca81;
          margin: 10px 0 30px 0;
          border-radius: 2px;
        }
        .text-p {
          color: #555;
          line-height: 40px;
        }
      }
    }
  }
  .tb60{
    padding: 100px 0;
  }
}
</style>
